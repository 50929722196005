export const services = [
  { id: 'event', name: 'Rendezvény, riport' },
  { id: 'object', name: 'Tárgy, étel' },
  { id: 'portrait', name: 'Portré' },
  { id: 'interior', name: 'Enteriőr' },
  { id: 'property', name: 'Enteriőr' },
  { id: 'group', name: 'Csoport, család' },
  { id: 'familyAndGroup', name: 'Csoport, család' },
  { id: 'wedding', name: 'Esküvő' },
  { id: 'video', name: 'Videó' },
  { id: 'food', name: 'Étel' },
  { id: 'product', name: 'Tárgy' },
  { id: 'custom', name: 'Egyedi' },
];
const idsToRemove = ['interior', 'group', 'object'];
export const filteredServices = services.filter(service => !idsToRemove.includes(service.id));

export const languages = [
  { id: 'en', name: 'Angol' },
  { id: 'hu', name: 'Magyar' },
];

export const inquiryStatesMap = {
  created: 'Ajánlatokra vár',
  purchased: 'Folyamatban',
  delivered: 'Szállítva',
  accepted: 'Lezárva'
};

export const plusShootingTypes = [
  { id: 'video', name: 'Videó' },
  { id: 'photo', name: 'Fotó' },
];

export const plusCategories = [
  {name: 'Rendezvény', id: 'event'},
  {name: 'Portré', id: 'portrait'},
  {name: 'Ingatlan', id: 'property'},
  {name: 'Esküvő', id: 'wedding'},
  {name: 'Csoport és család', id: 'familyAndGroup'},
  {name: 'Étel', id: 'food'},
  {name: 'Termék', id: 'product'},
  {name: 'Egyedi', id: 'custom'},
];


export const plusPackageStatuses = [
  { id: 'enabled', name: 'Elerheto' },
  { id: 'pending', name: 'Kidolgozas alatt' },
  { id: 'disabled', name: 'Kivezetett' },
];

export const plusProjectDrivers = [
    { id: 'pricing_dynamic', name: 'Dinamikus árazás limittel' },
    { id: 'pricing_dynamic_fix_over_limit', name: 'Dinamikus árazás limittel, limiten túli fix árazással' },
    { id: 'pricing_dynamic_with_correction', name: 'Dinamikus árazás limittel, várható képszám alatti korrekcióval' },
    { id: 'delivery_on_workday', name: 'Határidők munkanapon' },
    { id: 'delivery_at_time', name: 'Határidők időponttal megadva' },
    {id: 'pricing-extra-pics-with-corrections', name: 'Sima árazás, várható képszám szerinti korrekcióval'}
];

export const timeUnits = [
  {id: 'h', name: 'ora'},
  {id: 'd', name: 'nap'},
  {id: 'M', name: 'honap'},
]

export const extraUnits = [
  {id: 'h', name: 'ora'},
  {id: 'pc', name: 'db'},
]

export const videoLengthUnits = [
  {id: 'másodperc', name: 'másodperc'},
  {id: 'perc', name: 'perc'},
  {id: 'óra', name: 'óra'},
]

export const userStates = [
  {id: 'deletedUser', name: 'Törölt'},
  {id: 'activeUser', name: 'Aktív'},
  {id: 'inactiveUser', name: 'Inaktív'},
  {id: 'justRegUser', name: 'Most regisztrált'},
  {id: 'expiredUser', name: 'Lejárt'},
  {id: 'disabledUser', name: 'Tiltott'},
  {id: 'verifiedUser', name: 'Visszaigazolt'},
]

export const userRoles = [
  { id: 'managerPlus', name: 'Menedzser' },
  { id: 'ownerPlus', name: 'Tulajdonos' },
  { id: 'adminPlus', name: 'Admin' },
]

export const currencies = [
  { id: 'HUF', name: 'Forint' },
  { id: 'EUR', name: 'Euró' },
]

export const plusInviteStatuses = [
  { id: 'invited', name: 'Meghívott' },
  { id: 'invited_twice', name: 'Meghívott 2x' },
  { id: 'invited_third', name: 'Meghívott 3x' },
  { id: 'finished', name: 'Regisztrált' },
]

export const partnerCountryCodes = [
  { id: 'HU', name: 'Magyarország' },
  { id: 'AT', name: 'Ausztria' },
  { id: 'DE', name: 'Németország' },
  { id: 'LU', name: 'Luxemburg' },
  { id: 'AL', name: 'Albánia' },
  { id: 'SE', name: 'Svédország' },
  { id: 'UZ', name: 'Üzbekisztán' },
  { id: 'SK', name: 'Szlovákia' },
  { id: 'LT', name: 'Litvánia' },
  { id: 'LV', name: 'Lettország' },
  { id: 'EE', name: 'Észtország' },
  { id: 'XK', name: 'Koszovó' },
]
