import React from 'react';

import Chip from 'material-ui/Chip';
import {
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateField,
    DisabledInput,
    Edit,
    EditButton,
    Filter,
    FunctionField,
    ImageField,
    ImageInput,
    List,
    LongTextInput,
    NumberInput,
    RadioButtonGroupInput,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    SaveButton,
    SelectArrayInput,
    SelectField,
    SelectInput,
    Show,
    ShowButton,
    SingleFieldList,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    Title,
    Toolbar,
} from 'admin-on-rest';
import {
    currencies,
    extraUnits,
    plusCategories,
    plusPackageStatuses,
    plusProjectDrivers,
    plusShootingTypes,
    timeUnits,
    videoLengthUnits,
} from "../constants";
import PlusPackageActivateButton from "../components/PlusPackageActivateButton";
import PlusPackageDeactivateButton from "../components/PlusPackageDeactivateButton";
import PlusPackageDeleteButton from "../components/PlusPackageDeleteButton";
import PlusPackageModifyResolveButton from "../components/PlusPackageModifyResolveButton";
import DeleteButton from "admin-on-rest/lib/mui/button/DeleteButton";
import {
    EmbeddedArrayField,
    EmbeddedArrayInput,
} from "aor-embedded-array";
import SendEmailButton from '../components/SendEmailButton'


const statuses = [
    {id: 'enabled', name: 'Elérhető'},
    {id: 'disabled', name: 'Kivezetett'},
    {id: 'pending', name: 'Kidolgozás alatt'},
];

const PackageTitle = ({record}) => {
    return <span>Csomag: {record ? `${record.name} - ${record['shootingType']} (${record.id})` : ''}</span>;
};

const PlusPackageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Cég" source="q"/>
        <SelectInput label="Csomag fázis" source="s" choices={statuses}/>
    </Filter>
);

const PackageEditCreateToolbar = props => <Toolbar {...props} >
    <SaveButton label="Mentés" redirect="list" submitOnEnter={true}/>
    {/*<SaveButton label="Mentés + Új" redirect={false} submitOnEnter={false} raised={false} />*/}
</Toolbar>;

const PackageModificationActionBar = (props) => <FunctionField
    {...props}
    label="Kezelés"
    source='status'
    render={
        (record, source) => <React.Fragment>
            <SelectField disabled label="Jelenlegi státusz:" record={record} source={source}
                         choices={plusPackageStatuses}/>
            {
                record[source] === 'enabled'
                && <PlusPackageDeactivateButton record={record}/>
            }
            {
                record[source] !== 'enabled'
                && <div>
                    <PlusPackageDeleteButton record={record}/>
                    <PlusPackageActivateButton record={record}/>
                </div>
            }
        </React.Fragment>
    }
/>

const PackageForm = (props) => <SimpleForm redirect="list" toolbar={<PackageEditCreateToolbar/>} {...props}>
    <DisabledInput source="id"/>
    <TextInput label="Név" source="name"/>

    <PackageModificationActionBar/>

    <ReferenceInput label='Kapcsolódó Cég' source="clientId_id" reference="active-clients" perPage={100}>
        {
            props.create
                ? <SelectInput optionText="name"/>
                : <DisabledInput source='name'/>
        }
    </ReferenceInput>

    <RadioButtonGroupInput
        translate={(l) => l}
        translateChoice={false}
        label="Tipus"
        source="shootingType"
        choices={plusShootingTypes}
    />
    <RadioButtonGroupInput
        translate={(l) => l}
        translateChoice={false}
        source="category"
        label="Terulet"
        choices={plusCategories}
    />
    <SelectArrayInput label="Projekt vezérlők" source="projectDrivers" choices={plusProjectDrivers} />
    <LongTextInput label="Leírás" source="description" />
    <TextInput label="Brief link" source="brief" />
    <TextInput label="Editor brief and pricing" source="editorBrief" />

    <hr/>
    <Title defaultTitle='Csomag ár'/>
    <SelectInput label="Penznem" source="price.currency" choices={currencies}/>
    <NumberInput label="Osszeg" source="price.amount"/>

    <hr/>
    <Title defaultTitle='Fotós honorárium'/>
    <SelectInput label="Penznem" source="priceOfPhotographer.currency" choices={currencies}/>
    <NumberInput label="Osszeg" source="priceOfPhotographer.amount"/>

    <hr/>
    <Title defaultTitle='Munka hossza'/>
    <NumberInput label="Hossz" source="duration.amount"/>
    <SelectInput label="Mertek" source="duration.unit" choices={timeUnits}/>

    <hr/>
    <Title defaultTitle='Leadasi ido'/>
    <NumberInput label="Hossz" source="deliveryTime.amount"/>
    <SelectInput label="Mertek" source="deliveryTime.unit" choices={timeUnits}/>
    <NumberInput
        label="Időpontban (0 - 23, pl: 9 => 9AM helyszíni idő)"
        source='deliveryTime.atHour'
    />

    <hr/>
    <Title defaultTitle='Fotos leadasi ido'/>
    <NumberInput label="Hossz" source="photographerDeliveryTime.amount"/>
    <SelectInput label="Mertek" source="photographerDeliveryTime.unit" choices={timeUnits}/>
    <NumberInput
        label="Időpontban (0 - 23, pl: 9 => 9AM helyszíni idő)"
        source='photographerDeliveryTime.atHour'
    />

    <hr/>
    <NumberInput label="Atadott kepek szama" source="deliveredPicsNum"/>
    <NumberInput label="Retusalt kepek szama" source="retouchedPicsNum"/>

    <hr/>
    <Title defaultTitle='Video hossza'/>
    <NumberInput label="Hossz" source="videoLength.amount"/>
    <SelectInput label="Mertek" source="videoLength.unit" choices={videoLengthUnits}/>

    <hr/>
    <EmbeddedArrayInput label="Extrák" source="extras">
        <TextInput label="Név" source="name"/>
        <TextInput label="Számla tétel név" source="invoiceName"/>
        <SelectInput label="Mérték" source="unit" choices={extraUnits}/>
        <SelectInput label="Pénznem" source="currency" choices={currencies}/>
        <NumberInput label="Ár" source="amount"/>
        <NumberInput label="Fotós ár" source="creativeAmount"/>
    </EmbeddedArrayInput>

    <hr/>
    <Title defaultTitle='Dinamikus árazás'/>
    <NumberInput label="Felső képszám limit" source="dynamicPricing.upperLimit"/>
    <NumberInput label="Egységár ügyfél számára" source="dynamicPricing.unitPriceForClient"/>
    <NumberInput label="Egységár kreatív számára" source="dynamicPricing.unitPriceForCreative"/>
    <NumberInput label="Egységár editor számára" source="dynamicPricing.unitPriceForEditor"/>
    <NumberInput label="Fix limit feletti egységár ügyfél számára"
                 source="dynamicPricing.aboveLimitUnitPriceForClient"/>
    <NumberInput label="Fix limit feletti egységár kreatív számára"
                 source="dynamicPricing.aboveLimitUnitPriceForCreative"/>
    <NumberInput label="Fix limit feletti egységár editor számára"
                 source="dynamicPricing.aboveLimitUnitPriceForEditor"/>
    <NumberInput label="Szorzó" source="dynamicPricing.rate"/>
    <SelectInput label="Pénznem" source="dynamicPricing.currency" choices={currencies}/>

    <hr/>
    <ReferenceArrayInput
        label='Fotósok akik jelentkezhetnek'
        source="photographers_id"
        reference="creatives"
        perPage={1000}
        InputProps={{maxRows: 8}}
        filter={{"round": "accepted-two"}}
        sort={{field: 'name', order: 'ASC'}}
    >
        <SelectArrayInput optionText="name"  elStyle={{width: '100%'}}/>
    </ReferenceArrayInput>

    <hr/>
    <BooleanInput label="Manuális szerkesztés engedélyezve" source="manualEditingEnabled"/>
    <ReferenceArrayInput
        label='Editorok akik jelentkezhetnek'
        source="editors_id"
        reference="editors"
        perPage={1000}
        InputProps={{maxRows: 8}}
        sort={{field: 'name', order: 'ASC'}}
    >
        <SelectArrayInput optionText="name" elStyle={{width: '100%'}}/>
    </ReferenceArrayInput>
    <hr/>

    <Title defaultTitle='Manuál szerkesztési díj'/>
    <SelectInput label="Pénznem" source="manualEditingPrice.currency" choices={currencies}/>
    <NumberInput label="Összeg" source="manualEditingPrice.amount"/>
    <hr/>
    <BooleanInput label="AI kép szerkesztés engedélyezve" source="backgroundRemovalEnabled"/>

    <Title defaultTitle='Fotó szerkesztési díj'/>
    <SelectInput label="Penznem" source="editingPrice.currency" choices={currencies}/>
    <NumberInput label="Osszeg" source="editingPrice.amount"/>


    <EmbeddedArrayInput label="Szerkesztési lehetőségek" source="imageEditingConfigs">
        <TextInput label="Név" source="name"/>
        <ReferenceArrayInput
            reference="photoroom-editing-templates"
            source="templates"
            label='Szerkesztő sablonok'
            perPage={1000}
            InputProps={{maxRows: 8}}
            filter={{"status": "active"}}
            sort={{field: 'name', order: 'ASC'}}
        >
            <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
        <NumberInput label="Fényképek száma" source="limit" />
        <BooleanInput label="Kép feltöltése nem szükséges" source="notMandatory" />
        <ImageInput source="examplePicture" label="Related pictures" accept="image/*" maxSize={10 * 1024 * 1024}
                    multiple={false}>
            <ImageField source="url" title="name"/>
        </ImageInput>
    </EmbeddedArrayInput>

    <hr/>
    <ImageInput source="refPics" label="Referencia fotók" accept="image/*" multiple={true}>
        <ImageField source="url" title="name"/>
    </ImageInput>

    <DisabledInput label='Utolsó változás:' source="updatedAt"/>
    <DisabledInput label='Létrehozva:' source="createdAt"/>
</SimpleForm>;

export const PlusPackageEdit = (props) => (
    <Edit title={<PackageTitle/>} {...props} >
        <PackageForm/>
    </Edit>
);

const DriversField = ({record}) => (
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {
            !record['projectDrivers']
            && <p>Nincsenek projekt verzérlők</p>
        }
        {
            record['projectDrivers']
            && Array.isArray(record['projectDrivers'])
            && record['projectDrivers'].map(
                (item, idx) => <Chip key={idx} style={{margin: 4}}>
                    {item}
                </Chip>
            )
        }
    </div>
)

export const PlusPackageShow = (props) => <Show {...props} title='Plus csomag'>
    <SimpleShowLayout>
        <TextField label="Id" source="id"/>
        <TextField label="Név" source="name"/>

        <ReferenceField label='Kapcsolódó Cég' source="clientId_id" reference="enterprise-clients">
            <TextField source='name'/>
        </ReferenceField>

        <SelectField source="shootingType" choices={plusShootingTypes}/>
        <SelectField source="category" choices={plusCategories}/>
        <DriversField/>

        <TextField label="Leírás" source="description" />
        <TextField label="Brief link" source="brief" />
        <TextField label="Editor brief and pricing" source="editorBrief" />

        <hr/>
        <Title defaultTitle='Csomag ár'/>
        <SelectField label="Penznem" source="price.currency" choices={currencies}/>
        <TextField label="Osszeg" source="price.amount"/>

        <hr/>
        <Title defaultTitle='Fotós honorárium'/>
        <SelectField label="Penznem" source="priceOfPhotographer.currency" choices={currencies}/>
        <TextField label="Osszeg" source="priceOfPhotographer.amount"/>

        <hr/>
        <Title defaultTitle='Munka hossza'/>
        <TextField label="Hossz" source="duration.amount"/>
        <SelectField label="Mertek" source="duration.unit" choices={timeUnits}/>

        <hr/>
        <Title defaultTitle='Leadasi ido'/>
        <TextField label="Hossz" source="deliveryTime.amount"/>
        <SelectField label="Mertek" source="deliveryTime.unit" choices={timeUnits}/>
        <TextField
            label="Időpontban (0 - 23, pl: 9 => 9AM helyszíni idő)"
            source='deliveryTime.atHour'
        />

        <hr/>
        <Title defaultTitle='Fotos leadasi ido'/>
        <TextField label="Hossz" source="photographerDeliveryTime.amount"/>
        <SelectField label="Mertek" source="photographerDeliveryTime.unit" choices={timeUnits}/>
        <TextField
            label="Időpontban (0 - 23, pl: 9 => 9AM helyszíni idő)"
            source='photographerDeliveryTime.atHour'
        />

        <hr/>
        <TextField label="Atadott kepek szama" source="deliveredPicsNum"/>
        <TextField label="Retusalt kepek szama" source="retouchedPicsNum"/>

        <hr/>
        <Title defaultTitle='Video hossza'/>
        <TextField label="Hossz" source="videoLength.amount"/>
        <SelectField label="Mertek" source="videoLength.unit" choices={videoLengthUnits}/>

        <hr/>
        <EmbeddedArrayField label="Extrák" source="extras">
            <TextField label="Név" source="name"/>
            <TextField label="Számla tétel név" source="invoiceName"/>
            <SelectField label="Mérték" source="unit" choices={extraUnits}/>
            <SelectField label="Pénznem" source="currency" choices={currencies}/>
            <TextField label="Ár" source="amount"/>
            <TextField label="Fotós ár" source="creativeAmount"/>
        </EmbeddedArrayField>

        <hr/>
        <Title defaultTitle='Dinamikus árazás'/>
        <TextField label="Felső képszám limit" source="dynamicPricing.upperLimit"/>
        <TextField label="Egységár ügyfél számára" source="dynamicPricing.unitPriceForClient"/>
        <TextField label="Egységár kreatív számára" source="dynamicPricing.unitPriceForCreative"/>
        <TextField label="Egységár editor számára" source="dynamicPricing.unitPriceForEditor"/>
        <TextField label="Fix limit feletti egységár ügyfél számára"
                   source="dynamicPricing.aboveLimitUnitPriceForClient"/>
        <TextField label="Fix limit feletti egységár kreatív számára"
                   source="dynamicPricing.aboveLimitUnitPriceForCreative"/>
        <TextField label="Fix limit feletti egységár editor számára"
                   source="dynamicPricing.aboveLimitUnitPriceForEditor"/>
        <TextField label="Szorzó" source="dynamicPricing.rate"/>
        <SelectField label="Pénznem" source="dynamicPricing.currency" choices={currencies}/>
        <hr/>

        <hr/>
        <FunctionField
            label='Fotósok akik jelentkezhetnek'
            source="photographers_id"
            render={
                (record, source) => {
                    if (record[source]) {
                        return (
                        <ReferenceArrayField
                            source="photographers_id"
                            record={record}
                            reference="creatives"
                            basePath='/'
                        >
                            <SingleFieldList>
                                <ChipField source="name" />
                            </SingleFieldList>
                        </ReferenceArrayField>
                    )
                    } else {
                        return <p>Nincsenek hozzárendelt fotósok</p>
                    }
                }
            }
        />
        <SendEmailButton type={'creatives'}/>

        <hr/>
        <BooleanField label="Manuális szerkesztés engedélyezve" source="manualEditingEnabled"/>
        <FunctionField
            label="Szerkesztők"
            render={
                (record, source) => {
                    if (record[source]) {
                        return <React.Fragment>
                            <ReferenceArrayField
                                source='editors_id'
                                record={record}
                                resource='editors'
                                reference="editors"
                                basePath='/'
                            >
                                <TextField source="name"/>
                            </ReferenceArrayField>
                            <SendEmailButton type={'editors'}/>
                        </React.Fragment>
                    } else {
                        return <p>Nincsenek hozzárendelt szerkesztők</p>
                    }
                }
            }
        />

        <hr/>
        <Title defaultTitle='Manuál szerkesztési díj'/>
        <SelectField label="Pénznem" source="manualEditingPrice.currency" choices={currencies}/>
        <TextField label="Összeg" source="manualEditingPrice.amount"/>

        <hr/>
        <BooleanField label="AI kép szerkesztés engedélyezve" source="backgroundRemovalEnabled"/>

        <Title defaultTitle='Fotó szerkesztési díj'/>
        <SelectField label="Penznem" source="editingPrice.currency" choices={currencies}/>
        <TextField label="Osszeg" source="editingPrice.amount"/>

        <EmbeddedArrayField label="Szerkesztési lehetőségek" source="imageEditingConfigs">
            <TextField label="Név" source="name"/>
            <ReferenceArrayField reference="photoroom-editing-templates" source="templates" label='Szerkesztő sablonok'>
                <TextField source="name"/>
            </ReferenceArrayField>
            <TextField label="Fényképek száma" source="limit"/>

            <ImageField source="examplePicture" src="url" title="name"/>
        </EmbeddedArrayField>

        <hr/>
        <ImageField source="refPics" src="url" title="name"/>

        <DateField label='Utolsó változás:' source="updatedAt" locales="hu-HU" showTime/>
        <DateField label='Létrehozva:' source="createdAt" locales="hu-HU" showTime/>

    </SimpleShowLayout>
</Show>;

export const PlusPackageCreate = (props) => <Create title={<PackageTitle/>} {...props}>
    <PackageForm create/>
</Create>

const rowStyle = (record, _) => ({
    backgroundColor: (record.status === 'enabled' && 'aquamarine')
        || (record.status === 'disabled' && 'lightpink')
        || 'white',
});

export const PlusPackageList = (props) => (
    <List title="Plus csomagok" filters={<PlusPackageFilter/>}{...props}>
        <Datagrid rowStyle={rowStyle}>
            <TextField label="Elnevezes" source="name"/>
            <TextField label="Leiras" source="description"/>
            <TextField label="Kategoria" source="category"/>
            <TextField label="Tipus" source="shootingType"/>
            <DriversField/>
            <ReferenceField source='clientId' label='Kapcsolódó Cég' reference='enterprise-clients'>
                <TextField source='name'/>
            </ReferenceField>
            <DateField label='Létrehozva:' source="createdAt" showTime locales="hu-HU"/>
            <SelectField source='status' choices={plusPackageStatuses}/>
            <PackageModificationActionBar/>
            <ShowButton/>
            <EditButton/>
        </Datagrid>
    </List>
);

// modification requests
export const PackageModifyForm = (props) => <SimpleForm redirect="list" {...props}>
    <DisabledInput source="id"/>
    <ReferenceInput label='Kapcsolódó Csomag' source="packageId" reference="enterprise-packages">
        <SelectInput optionText="name"/>
    </ReferenceInput>

    <LongTextInput source="description"/>
</SimpleForm>

export const PlusPackageModifyEdit = (props) => <Edit {...props}>
    <PackageModifyForm/>
</Edit>;

export const PlusPackageModifyCreate = (props) => <Create {...props}>
    <PackageModifyForm/>
</Create>

const modifyRowStyle = (record, _) => ({
    backgroundColor: (!!record.resolved && 'aquamarine') || 'white',
});

export const PlusPackageModifyList = (props) => (
    <List
        title="Plus csomag módosítások"
        sort={{field: 'createdAt', order: 'DESC'}}
        {...props}
    >
        <Datagrid rowStyle={modifyRowStyle}>
            <DateField label="Létrehozva" source="createdAt" showTime/>
            <TextField label="Leiras" source="description"/>
            <ReferenceField source='packageId' label='Kapcsolódó Csomag' reference='enterprise-packages'>
                <TextField source='name'/>
            </ReferenceField>
            <FunctionField
                label="Befejezve"
                source="resolved"
                render={
                    (record, source) => {
                        if (record[source]) {
                            const value = record[source];

                            return <span>
                                <ReferenceField source='by' record={value} reference="backend-users" basePath="/"
                                                linkType='show'>
                                    <TextField label='Engedélyező' source='name'/>
                                </ReferenceField>
                                <br/>
                                <DateField source='time' record={value}/>
                            </span>
                        } else {
                            return <PlusPackageModifyResolveButton record={record}/>
                        }
                    }
                }
            />
            <EditButton/>
            <DeleteButton/>
        </Datagrid>
    </List>
);
